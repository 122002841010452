import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

// @components
import { NormalBannerText } from "./NormalBannerText";
import { ChristmasBannerText } from "./ChristmasBannerText";

// @icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import usePathname from "../../hooks/usePathname";

const WebAnnouncement = ({ closeBanner, handleCloseBanner }) => {
  let promoCodeText;
  const hasV1 = usePathname();
  const location = useLocation();
  const [isChristmas] = useState(false);
  const whitelist = ["/signin", "/signup"];
  const [hasCopiedCode, setHasCopiedCode] = useState(false);

  const handleCopyPromoCode = async () => {
    try {
      promoCodeText = "FAST10";
      await navigator.clipboard.writeText(promoCodeText);
      setHasCopiedCode(true);
      setTimeout(() => {
        setHasCopiedCode(false);
      }, 2000);
    } catch (error) {
      console.error(error);
    }
  };

  if (whitelist.includes(location.pathname)) {
    return null;
  } else if (closeBanner === true) {
    return (
      <Link
        to={`${hasV1 ? "/bootcamp/v1/paths": "/bootcamp/paths"}`}
        className={`z-[11] sticky top-0 block`}
      >
        <div className="xs:w-full lg:w-auto sticky inset-0 z-[99999] h-[54px]">
          <div className="bg-[#2b2c54] h-full">
            <div className="relative flex items-center web-announcement justify-between max-[760px]:justify-center gap-1 py-2 max-w-[1320px] mx-auto w-full h-full">
              {isChristmas ? (
                <ChristmasBannerText />
              ) : (
                <NormalBannerText
                  hasCopiedCode={hasCopiedCode}
                  promoCodeText={promoCodeText}
                  handleCopyPromoCode={handleCopyPromoCode}
                />
              )}
            </div>
          </div>
          <div className="absolute right-3 top-3">
            <button onClick={handleCloseBanner}>
              <CloseRoundedIcon
                style={{ color: "#fff", fontSize: "14px" }}
              />
            </button>
          </div>
        </div>
      </Link>
    );
  } else return null;
};

export const ApplicationFeeBanner = () => (
  <div className="bg-[#2B2C54] py-3 px-4">
    <p className="text-xl text-white text-center">
      Click to <Link to="/pricing" className="text-[#FF8C00] font-medium">Enrol now</Link> to
      gain full access to your Data Bootcamp
    </p>
  </div>
);

export default WebAnnouncement;
