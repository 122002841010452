import { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { navbarItems } from "../../constants/navbar/navbar";
import NavbarItemsCard from "../NavbarItemsCard/NavbarItemsCard";

const NavbarItems = ({ landingPage }) => {
  return (
    <div className="h-full flex flex-col justify-center relative w-full">
      <ul
        className={`flex flex-row items-center ${
          landingPage ? "w-full flex justify-between gap-4" : "gap-4"
        } h-full`}
      >
        {navbarItems.map((item, index) =>
          item?.itemLink && item.itemTitle !== "Bootcamp" ? (
            <li
              key={index}
              className={`${
                landingPage
                  ? "text-white hover:text-orange-500 text-base leading-5 font-light"
                  : "text-[#111] hover:text-[#2b2c54] text-xs font-medium"
              } relative h-full flex justify-center items-center`}
            >
              <Link
                to={item.itemLink}
                aria-label={`${item.itemTitle}`}
              >
                <span>
                  {item.itemTitle}
                  {item.isNew && item.isNew === true ? (
                    landingPage ? (
                      <span
                        className="text-xs text-orange-500 ml-1 bg-[#FF8C0026] leading-4 rounded-sm px-[7px] py-[3.5px]"
                        aria-label="New feature"
                      >
                        New
                      </span>
                    ) : (
                      <span className="absolute -top-4 -right-3 bg-[#ff8c00] text-white rounded px-1">
                        New
                      </span>
                    )
                  ) : null}
                </span>
              </Link>
            </li>
          ) : item.itemTitle === "Bootcamp" ? (
            <Fragment key={index}>
              <ItemList
                landingPage={landingPage}
                item={item}
              />
            </Fragment>
          ) : (
            <Fragment key={index}>
              <ItemList
                landingPage={landingPage}
                item={item}
              />
            </Fragment>
          )
        )}
      </ul>
    </div>
  );
};

const ItemList = ({ item, landingPage }) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [menuId, setMenuId] = useState("");

  useEffect(() => {
    const calculateContentHeight = () => {
      if (contentRef.current) {
        const height = contentRef.current.scrollHeight;
        setContentHeight(height);
      }
    };

    calculateContentHeight();

    const handleResize = () => {
      calculateContentHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [menuId]);

  return (
    <li
      onMouseLeave={() => setMenuId("")}
      onMouseOver={() => setMenuId(item.itemId)}
      className={`${
        landingPage
          ? "text-white hover:text-orange-500 text-base leading-5 font-light relative"
          : "text-[#111] hover:text-[#2b2c54] text-xs font-medium"
      } cursor-pointer h-full flex justify-center items-center`}
    >
      <span>
        {item.itemLink ? (
          <Link
            to={item.itemLink}
            aria-label={`${item.itemTitle}`}
            className="relative"
          >
            {item.itemTitle}
            {item.isNew && item.isNew === true ? (
              landingPage ? (
                <span
                  className="text-xs text-orange-500 ml-1 bg-[#FF8C0026] leading-4 rounded-sm px-[7px] py-[3.5px]"
                  aria-label="New feature"
                >
                  New
                </span>
              ) : (
                <span className="absolute -top-4 -right-3 bg-[#ff8c00] text-white rounded px-1">
                  New
                </span>
              )
            ) : null}
          </Link>
        ) : (
          <>{item.itemTitle}</>
        )}
      </span>
      {item.itemList && (
        <div
          ref={contentRef}
          style={{
            height: menuId ? `${contentHeight}px` : "0",
          }}
          className="absolute text-black z-50 top-[100%] left-0 w-[19.3925rem] overflow-hidden transition-all duration-100 ease-in"
        >
          <div className={`border shadow-sm bg-white`}>
            {item.icon && (
              <div className="flex flex-row items-center gap-3 bg-white p-6">
                <span
                  style={{
                    width: "32px",
                    height: "32px",
                    padding: "4px",
                    display: "flex",
                    borderRadius: "100%",
                    alignItems: "center",
                    background: "#f1f3f4",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </span>
                <h4 className="text-xs font-medium">
                  {item.itemTitle}
                </h4>
              </div>
            )}
            <div className="flex flex-col justify-center gap-3 bg-[#eee] p-6">
              {item.itemList?.map((el, index) => (
                <NavbarItemsCard
                  key={index}
                  title={el.itemListTitle}
                  path={el.itemListLink}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default NavbarItems;
