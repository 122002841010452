export const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    fill="none"
    viewBox="0 0 32 33"
  >
    <path
      fill="#15175C"
      d="M0 16.56c0 8.836 7.163 16 16 16s16-7.164 16-16-7.163-16-16-16-16 7.163-16 16"
    ></path>
    <path
      fill="#fff"
      d="M16.003 9.76h3.4c.8 0 1.2.2 1.5.3.4.2.7.3 1 .6s.5.6.6 1c.1.3.2.7.3 1.5v6.8c0 .8-.2 1.2-.3 1.5-.2.4-.3.7-.6 1s-.6.5-1 .6c-.3.1-.7.2-1.5.3h-6.8c-.8 0-1.2-.2-1.5-.3-.4-.2-.7-.3-1-.6s-.5-.6-.6-1c-.1-.3-.2-.7-.3-1.5v-6.8c0-.8.2-1.2.3-1.5.2-.4.3-.7.6-1s.6-.5 1-.6c.3-.1.7-.2 1.5-.3zm0-1.5h-3.4c-.9 0-1.5.2-2 .4s-1 .5-1.5 1-.7.9-1 1.5c-.2.5-.3 1.1-.4 2v6.8c0 .9.2 1.5.4 2s.5 1 1 1.5.9.7 1.5 1c.5.2 1.1.3 2 .4h6.8c.9 0 1.5-.2 2-.4s1-.5 1.5-1 .7-.9 1-1.5c.2-.5.3-1.1.4-2v-6.8c0-.9-.2-1.5-.4-2s-.5-1-1-1.5-.9-.7-1.5-1c-.5-.2-1.1-.3-2-.4z"
    ></path>
    <path
      fill="#fff"
      d="M16.003 12.26c-2.4 0-4.3 1.9-4.3 4.3s1.9 4.3 4.3 4.3 4.3-1.9 4.3-4.3-1.9-4.3-4.3-4.3m0 7.1c-1.5 0-2.8-1.2-2.8-2.8 0-1.5 1.2-2.8 2.8-2.8 1.5 0 2.8 1.2 2.8 2.8 0 1.5-1.3 2.8-2.8 2.8M20.403 13.16a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
    ></path>
  </svg>
);

export const LinkedInIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    fill="none"
    viewBox="0 0 32 33"
  >
    <path
      fill="#15175C"
      d="M0 16.56c0 8.836 7.163 16 16 16s16-7.164 16-16-7.163-16-16-16-16 7.163-16 16"
    ></path>
    <path
      fill="#fff"
      d="M11.6 24.56H8.2v-10.7h3.4zm-1.7-12.2c-1.1 0-1.9-.8-1.9-1.9s.9-1.9 1.9-1.9c1.1 0 1.9.8 1.9 1.9s-.8 1.9-1.9 1.9M24 24.56h-3.4v-5.8c0-1.7-.7-2.2-1.7-2.2s-2 .8-2 2.3v5.7h-3.4v-10.7h3.2v1.5c.3-.7 1.5-1.8 3.2-1.8 1.9 0 3.9 1.1 3.9 4.4v6.6z"
    ></path>
  </svg>
);

export const XSvgLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 256 256"
  >
    <path
      fill="#fff"
      strokeMiterlimit="10"
      d="m2.867 3 6.87 9.818L2.733 21h2.647l5.539-6.49L15.46 21h5.911l-7.197-10.303L20.744 3H18.14l-5.143 6.01L8.8 3z"
      fontFamily="none"
      fontSize="none"
      fontWeight="none"
      style={{ mixBlendMode: "normal" }}
      textAnchor="none"
      transform="scale(10.66667)"
    ></path>
  </svg>
);
