export const urls = [
  {
    id: 1,
    url: '/signin',
  },
  {
    id: 2,
    url: '/signup',
  },
  {
    id: 3,
    url: '/user-details',
  },
  {
    id: 4,
    url: '/forgot-password',
  },
  {
    id: 5,
    url: '/verify-email',
  },
  {
    id: 6,
    url: '/',
  },
];

export const footer = [
  {
    id: 1,
    heading: `Courses`,
    subheadings: [
      {
        title: `Power BI`,
        link: `learn/learning-path/powerbi-analyst-path`,
      },
      {
        title: `Tableau`,
        link: `learn/learning-path/tableauviz-analyst-path`,
      },
      {
        title: `SQL`,
        link: `learn/learning-path/sql-analyst-path`,
      },
      {
        title: `Microsoft Excel`,
        link: `learn/learning-path/excel-analyst-path`,
      },
    ],
  },
  {
    id: 2,
    heading: `Products`,
    subheadings: [
      {
        title: `Cooking Gas Prices in Nigeria`,
        link: `/products/cooking-gas-prices-in-nigeria`,
      },
      {
        title: `Fuel Prices Tracker`,
        link: `/products/fuel-prices-in-nigeria`,
      },
      {
        title: `Data Visualization 101`,
        link: `/products/data-visualization-101`,
      },
      {
        title: `Whatsapp Chat Analysis`,
        link: `/products/whatsapp-chat-analysis`,
      },
      {
        title: `The Nobel Prize`,
        link: `/products/the-nobel-prize`,
      },
    ],
  },
  {
    id: 3,
    heading: `Resources`,
    subheadings: [
      {
        title: `Datasets`,
        link: `/resources/datasets`,
      },
      {
        title: `Blog`,
        link: `/resources/blog`,
      },
      {
        title: `Data Digest`,
        link: `/resources/datadigest`,
      },
      {
        title: `Impact Report`,
        link: `/resources/reports/impact-report-2023`,
      },
    ],
  },
  {
    id: 4,
    heading: `Get help`,
    subheadings: [
      {
        title: `About us`,
        link: `/about`,
      },
      {
        title: `Contact us`,
        link: `/contact-us`,
      },
      {
        title: `Terms of use`,
        link: `/terms-of-use`,
      },
    ],
  },
];
