import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";

const usePathname = () => {
  const {pathname} = useLocation()
  const [versionOnePath, setVersionOnePath] = useState(false);

  useEffect(() => {
    const v1Pattern = /\/v1/;
    const hasV1 = v1Pattern.test(pathname);
    setVersionOnePath(hasV1);
  }, [pathname])

  return versionOnePath;
}

export default usePathname