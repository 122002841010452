// @icons
import SchoolIcon from '@mui/icons-material/School';
import LightbulbRoundedIcon from "@mui/icons-material/LightbulbRounded";
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded";
import SettingsIcon from '@mui/icons-material/Settings';

export const navbarItems = [
  {
    itemId: 11,
    itemTitle: `Solutions`,
    icon: <SettingsIcon fontSize="small" />,
    itemList: [
      {
        itemListTitle: `Tech Skills Training`,
        itemListLink: `/#skill-training`,
      },
      {
        itemListTitle: `Data Products`,
        itemListLink: `/#digital-product`,
      },
      {
        itemListTitle: `Application Development`,
        itemListLink: `/#app-dev`,
      },
    ],
  },
  {
    itemId: 1,
    itemTitle: `Bootcamp`,
    icon: <SchoolIcon fontSize="small" />,
    itemLink: `/bootcamp`,
    isNew: true,
    itemList: [
      {
        itemListTitle: `Data Analytics`,
        itemListLink: `/bootcamp/data-analytics`,
      },
      {
        itemListTitle: `Data Science`,
        itemListLink: `/bootcamp/data-science`,
      },
    ],
  },
  {
    itemId: 2,
    itemTitle: `Learn`,
    itemLink: `/learn`,
  },
  {
    itemId: 3,
    itemTitle: "Products",
    icon: <ShowChartRoundedIcon fontSize="small" />,
    itemList: [
      {
        itemListTitle: `Data Visualization 101`,
        itemListLink: `products/data-visualization-101`,
      },
      {
        itemListTitle: `Fuel Prices in Nigeria`,
        itemListLink: `products/fuel-prices-in-nigeria`,
      },
      {
        itemListTitle: `Whatsapp Chat Analysis`,
        itemListLink: `products/whatsapp-chat-analysis`,
      },
      {
        itemListTitle: `The Nobel Prize`,
        itemListLink: `products/the-nobel-prize`,
      },
      {
        itemListTitle: `Cooking Gas Prices in Nigeria`,
        itemListLink: `products/cooking-gas-prices-in-nigeria`,
      },
      {
        itemListTitle: `Breakdown of Nigeria's 2023 National Budget`,
        itemListLink: `products/breakdown-of-nigeria-s-2023-national-budget`,
      },
    ],
  },
  {
    itemId: 4,
    itemTitle: "Resources",
    icon: <LightbulbRoundedIcon fontSize="small" />,
    itemList: [
      {
        itemListTitle: `Blog`,
        itemListLink: `/resources/blog`,
      },
      {
        itemListTitle: `Datasets`,
        itemListLink: `/resources/datasets`,
      },
      {
        itemListTitle: `Data Digest`,
        itemListLink: `/resources/datadigest`,
      },
      {
        itemListTitle: `Impact Report`,
        itemListLink: `/resources/reports/impact-report-2023`,
      },
    ],
  },
  {
    itemId: 5,
    itemTitle: `Graduates`,
    itemLink: `/bootcamp/graduates`,
  },
];
