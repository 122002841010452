import { Link } from "react-router-dom";
import { footer } from "../../constants/paths/paths";

// @ assets
import resaLogo from "../../assets/logo.svg";

// @icons
import { InstagramIcon, LinkedInIcon, XSvgLogo } from "./SocialIcons";
import CopyrightRoundedIcon from "@mui/icons-material/CopyrightRounded";

// @styling
// import './footer.scss';

const Footer = () => {
  return (
    <div className="h-fit border-t pb-6 pt-24 px-4">
      <div className="lg:mx-auto max-w-[1100px] w-full">
        <div className="xs:flex xs:flex-col xs:gap-6 md:flex md:flex-row md:justify-between">
          <div className="xs:w-full md:w-[40%]">
            <div className="xs:w-[150px] md:w-[110px] h-[28px] xs:mt-4 lg:mt-0">
              <img
                className="max-w-full max-h-full"
                src={resaLogo}
                alt="resa logo"
              />
            </div>
            <div className="mt-4 max-w-72 text-[#8D8D8E] font-light leading-6">
              <p>
                Empowering individuals and businesses with the tools
                to harness data, drive innovation, and achieve
                excellence in a digital world.
              </p>
            </div>
            <div className="mt-8 max-w-72 flex items-center gap-4">
              <a
                target="_blank"
                href="https://linkedin.com/school/resagratia"
                className="transition-colors"
              >
                <LinkedInIcon />
              </a>
              <a
                target="_blank"
                href="https://instagram.com/resagratia"
                className="transition-colors"
              >
                <InstagramIcon />
              </a>
              <a
                target="_blank"
                href="https://x.com/resadata"
                className="transition-colors bg-[#171c5b] w-8 h-8 rounded-full flex justify-center items-center"
              >
                <XSvgLogo />
              </a>
            </div>
          </div>

          <div className="xs:w-full md:w-[60%] xs:flex xs:flex-col xs:gap-6 md:flex md:flex-row justify-between flex-1">
            {footer.map((heading, index) => (
              <FooterItem
                key={index}
                heading={heading}
                subheadings={heading.subheadings}
              />
            ))}
          </div>
        </div>

        <div className="my-12 text-[#8D8D8E] font-normal">
          <div className="flex items-center gap-1">
            <CopyrightRoundedIcon style={{ fontSize: 16 }} />
            <p className="text-sm leading-5">
              Copyright{" "}
              <span className="mr-1">{new Date().getFullYear()}</span>
              Resagratia. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

const FooterItem = ({ heading, subheadings }) => {
  return (
    <div className="">
      <h3
        key={heading.id}
        className="text-sm font-normal mb-6 text-[#15175C]"
      >
        {heading.heading}
      </h3>
      <ul className="flex flex-col gap-6">
        {subheadings.map((subheading, index) => {
          return (
            <Link
              className="text-xs text-[#414244] font-medium"
              key={index}
              to={subheading.link}
            >
              <li className="">{subheading.title}</li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};
