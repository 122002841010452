import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  dataScienceOffers,
  dataAnalyticsOffers,
  dataScienceBootcampLogos,
  dataAnalyticsBootcampLogos,
  introToDataAnalytics,
  introDataAnalyticsRoadmap,
} from "../BootcampLandingPage/constant/constants";
import useScrollToTop from "../../hooks/useScrollToTop";
import { SectionLayout } from "../../utils/SectionLayout";
import { BootcampOffers } from "../../utils/BootcampOffers";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import { analytics } from "../../firebase/firebase";
import { logEvent } from "firebase/analytics";
import usePathname from "../../hooks/usePathname";

const BootcampLearningPath = () => {
  useScrollToTop();
  const hasV1 = usePathname();
  const [searchParams] = useSearchParams();
  const referralLink = searchParams.get("ref-id");
  const [showError, setShowError] = useState(false);
  const [pathSelected, setPathSelected] = useState({
    dataScience: false,
    dataAnalytics: false,
    introDataAnalytics: false,
  });

  const saveRefIdToLocalStorage = () => {
    if (referralLink) {
      localStorage.setItem("refId", JSON.stringify(referralLink));
    }
  };

  useEffect(() => {
    saveRefIdToLocalStorage();
  }, []);

  const getRefId = () =>
    referralLink ||
    (localStorage.getItem("refId") &&
      JSON.parse(localStorage.getItem("refId")));

  return (
    <>
      <MetaTags
        path={`${hasV1 ? "/bootcamp/v1/paths" : "/bootcamp/paths"}`}
        title={"Resagratia | Bootcamp Path"}
        desc={"Select the Data Bootcamp to enrol in"}
        xPreviewImageLink="https://storage.googleapis.com/resagratia-webapp/misc/twitter_bootcamp_signup.png"
        fbPreviewImageLink="https://storage.googleapis.com/resagratia-webapp/misc/facebook_bootcamp_signup.png"
      />
      <main>
        <SectionLayout style="pb-[8.5rem] bg-[#FBFBFB]">
          <div className="max-[980px]:max-w-[32.71125rem] max-[980px]:mx-auto">
            <Link
              to={`${hasV1 ? "/bootcamp/v1" : "/bootcamp"}`}
              className="mt-7 flex items-center gap-2 w-max -ml-3"
            >
              <BackIcon /> <span>Back</span>
            </Link>
            <h1 className="font-normal text-[2.21775rem] text-center max-[980px]:text-start mb-[1.7742rem] leading-[2.6rem] sectionTitle mt-8">
              Select the{" "}
              <span className="text-[#FF8C00]">Data Bootcamp </span>{" "}
              to enrol in
            </h1>

            <h2 className="mt-10 font-normal text-[2rem] text-center mb-[1.7742rem] leading-[2.6rem] sectionTitle">
              Bootcamp Paths
            </h2>

            <div className="flex items-start justify-between gap-4 max-[980px]:flex-col max-[980px]:gap-4 mt-6">
              <div
                onClick={() =>
                  setPathSelected({
                    dataScience: false,
                    dataAnalytics: true,
                    introDataAnalytics: false,
                  })
                }
                className={`max-w-[32.71125rem] relative ${
                  pathSelected.dataAnalytics ? "border-[#ff8c00]" : ""
                } max-[980px]:max-w-full w-full border rounded-lg cursor-pointer`}
              >
                {pathSelected.dataAnalytics && <SelectedMark />}
                <BootcampOffers
                  bootcampPaths
                  allRoundedCorners
                  bgColor={`bg-[#fff]`}
                  pathOffers={dataAnalyticsOffers}
                  pathTitle="Data Analytics Bootcamp"
                  courseLogos={dataAnalyticsBootcampLogos}
                  TargetAudience="This bootcamp is perfect for those looking to start a career in data analysis or enhance their existing business and analytical skills."
                  desc="Designed for those eager to unravel the secrets hidden within vast datasets, this immersive program equips you with the tools and techniques to extract meaningful insights. From mastering the art of data visualization to honing your skills in data analysis and engineering, our bootcamp empowers you to make informed decisions and drive impactful changes in any industry."
                />
              </div>

              <div
                onClick={() =>
                  setPathSelected({
                    dataScience: true,
                    dataAnalytics: false,
                    introDataAnalytics: false,
                  })
                }
                className={`max-w-[32.71125rem] relative ${
                  pathSelected.dataScience ? "border-[#ff8c00]" : ""
                } max-[980px]:max-w-full w-full border rounded-lg cursor-pointer`}
              >
                {pathSelected.dataScience && <SelectedMark />}
                <BootcampOffers
                  bootcampPaths
                  allRoundedCorners
                  bgColor={`bg-[#fff]`}
                  pathOffers={dataScienceOffers}
                  pathTitle="Data Science Bootcamp"
                  courseLogos={dataScienceBootcampLogos}
                  TargetAudience="This bootcamp is ideal for those with a strong background in problem-solving and analytical thinking who want to pursue a career in data science or expand their skill set. We'll help you develop the necessary skills and techniques to excel in this field!"
                  desc="Tailored for aspiring data scientists, this intensive program delves deep into the realms of data manipulation, feature engineering, machine learning, predictive modeling, and artificial intelligence. Through hands-on projects and real-world applications, you'll learn to harness the power of data to build models that solve complex problems and shape the future of technology."
                />
              </div>
            </div>

            {/* <h2 className="mt-12 font-normal text-[2rem] text-center mb-[1.7742rem] leading-[2.6rem] sectionTitle">
              Course Tracks
            </h2>

            <div className="flex items-start justify-center gap-4 max-[980px]:flex-col max-[980px]:gap-4 mt-6">
              <div
                onClick={() =>
                  setPathSelected({
                    dataScience: false,
                    dataAnalytics: false,
                    introDataAnalytics: true,
                  })
                }
                className={`max-w-[32.71125rem] relative ${
                  pathSelected.introDataAnalytics
                    ? "border-[#ff8c00]"
                    : ""
                } max-[980px]:max-w-full w-full border rounded-lg cursor-pointer`}
              >
                {pathSelected.introDataAnalytics && <SelectedMark />}
                <BootcampOffers
                  bootcampPaths
                  allRoundedCorners
                  bgColor={`bg-[#fff]`}
                  pathOffers={introToDataAnalytics}
                  courseTrack={
                    introDataAnalyticsRoadmap.courseOutline
                  }
                  pathTitle="Introduction To Data Analytics"
                  desc="This course introduces you to the world of data analytics using Excel. You will learn to clean, manipulate, and visualise data. It is very beginner-friendly and you will build impressive portfolio projects."
                />
              </div>
            </div> */}

            <div className="text-center font-normal text-gray-700 mt-16 text-lg">
              <p>Not sure which tech career is right for you?</p>
              <p>
                Let's help you{" "}
                <Link
                  to="/quiz"
                  aria-label="quiz link"
                  className="text-blue-800 underline underline-offset-2"
                >
                  find out
                </Link>
                .
              </p>
            </div>

            <div className="mt-12">
              {pathSelected.dataAnalytics ||
              pathSelected.dataScience ||
              pathSelected.introDataAnalytics ? (
                <Link
                  onClick={() => {
                    logEvent(
                      analytics,
                      "bootcamp_path_continue_button_clicked",
                      {
                        category: "click",
                      }
                    );
                  }}
                  to={
                    pathSelected.dataAnalytics
                      ? getRefId()
                        ? hasV1
                          ? `/v1/application-fee?path=data-analytics&ref-id=${getRefId()}`
                          : `/application-fee?path=data-analytics&ref-id=${getRefId()}`
                        : hasV1
                        ? "/v1/application-fee?path=data-analytics"
                        : "/application-fee?path=data-analytics"
                      : pathSelected.introDataAnalytics
                      ? getRefId()
                        ? hasV1
                          ? `/v1/application-fee?path=intro-to-data-analytics&ref-id=${getRefId()}`
                          : `/application-fee?path=intro-to-data-analytics&ref-id=${getRefId()}`
                        : hasV1
                        ? "/v1/application-fee?path=intro-to-data-analytics"
                        : "/application-fee?path=intro-to-data-analytics"
                      : getRefId()
                      ? hasV1
                        ? `/v1/application-fee?path=data-science&ref-id=${getRefId()}`
                        : `/application-fee?path=data-science&ref-id=${getRefId()}`
                      : hasV1
                      ? "/v1/application-fee?path=data-science"
                      : "/application-fee?path=data-science"
                  }
                  className="block w-full mx-auto items-center gap-2 max-w-[25.4rem] text-white text-center font-medium py-2.5 text-lg bg-[#2b2c54] hover:bg-[#2b2c54ec] rounded-md"
                >
                  Continue
                </Link>
              ) : (
                <button
                  onClick={() => {
                    setShowError(true);
                    setTimeout(() => {
                      setShowError(false);
                    }, 3000);
                  }}
                  className="block w-full mx-auto items-center gap-2 max-w-[25.4rem] text-[#66646f] text-center font-normal py-2.5 text-lg bg-[#88878c2e] cursor-not-allowed rounded-md"
                >
                  Continue
                </button>
              )}
              <div className="relative mt-2 h-6">
                {showError && (
                  <span
                    className={`block absolute w-full top-0 pt-1.5 left-0 text-[#f03232] font-normal text-sm`}
                  >
                    Select the Data Bootcamp to enrol in
                  </span>
                )}
              </div>
            </div>
          </div>
        </SectionLayout>
      </main>
    </>
  );
};

const SelectedMark = () => (
  <span className="text-xs w-fit font-bold px-2 py-1 text-[#ff8c00] rounded flex flex-row items-center gap-1 absolute right-1 top-[1.25rem]">
    <CheckCircleIcon />
  </span>
);

export function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M12.44 19.06a1.5 1.5 0 010-2.12l8.485-8.486a1.5 1.5 0 112.121 2.121L15.621 18l7.425 7.425a1.5 1.5 0 01-2.121 2.121l-8.486-8.485z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default BootcampLearningPath;
