import { useEffect } from "react";
import { resizeWidth } from "../../redux/reducers/windowDimensionSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

// @components
import MobileNavBar from "../MobileNavBar/MobileNavBar";
import SearchField from "../SearchField/SearchField";
import NavbarItems from "../NavbarItems/NavbarItems";
import Buttons from "../Buttons/Buttons";
import AuthNavBar from "../AuthNavBar/AuthNavBar";

// @assets
import resaLogo from "../../assets/logo.svg";

// @styling
import "./navbar.scss";
import { ApplicationFeeBanner } from "../WebAnnouncement/WebAnnouncement";

const NavBar = ({ closeBanner }) => {
  const newWidth = useSelector(
    (state) => state.windowDimension.width
  );
  const isApplicationFee = useSelector(
    (state) => state.applicationFee.isApplicationFee
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const { currentUser } = useAuth();

  useEffect(() => {
    const handleWindowResize = () => {
      dispatch(resizeWidth({ width: window.innerWidth }));
    };

    window.addEventListener("resize", handleWindowResize);
    return () =>
      window.removeEventListener("resize", handleWindowResize);
  }, [dispatch, newWidth]);

  return (
    <>
      {newWidth <= 980 ? (
        <MobileNavBar
          closeBanner={closeBanner}
          isApplicationFee={isApplicationFee}
        />
      ) : (
        <header
          className={`navbar__header !sticky ${
            !closeBanner || !isApplicationFee ? "!top-0" : "!top-[55px]"
          }`}
        >
          <div className="px-8">
            <nav className="navbar">
              <div className="navbar__items">
                <div className="navbar__menu__container">
                  <div className="navbar__logo">
                    <Link to="/">
                      <img
                        src={resaLogo}
                        alt="resagratia logo"
                      />
                    </Link>
                  </div>

                  {/* navbar menu */}
                  <div className="h-full">
                    <NavbarItems />
                  </div>
                </div>

                {/* user sign up && sign in */}
                <div className="navbar__actions">
                  {/* <div className="w-[160px]">
                    <SearchField
                      placeholder="Search"
                      type="inline"
                      width="18"
                      height="18"
                    />
                  </div> */}

                  <div className="navbar__auth">
                    {currentUser ? (
                      <AuthNavBar />
                    ) : (
                      <div className="flex flex-row items-center gap-3">
                        <Buttons
                          style={`border border-[#2b2c54] bg-transparent text-xs font-bold text-[#2b2c54] py-2 px-4 hover:text-[#15175c]`}
                          hasLinkProp
                          path={"/signin"}
                          state={{ previousUrl: location.pathname }}
                        >
                          Sign in
                        </Buttons>
                        <Buttons
                          style={`border border-white bg-[#2b2c54] text-xs font-bold text-white py-2 px-4 hover:bg-[#15175c]`}
                          hasLinkProp
                          path={"/bootcamp"}
                          state={{ previousUrl: location.pathname }}
                        >
                          Get started
                        </Buttons>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </div>
          {isApplicationFee && <ApplicationFeeBanner />}
        </header>
      )}
    </>
  );
};

export default NavBar;
