import { Link } from 'react-router-dom';

const UserAuthMenu = ({ showAuth, setShowAuth, handleLogout }) => {
  return (
    <div className='absolute z-50 top-[100%] w-fit border bg-white shadow-sm px-8 py-3'  onMouseLeave={() => setShowAuth(!showAuth)}>
      <div className=''>
        <ul className='flex flex-col'>
          <Link className='inline-block' to='/dashboard'>
            <li className='my-3 text-xs text-[#111] font-medium hover:text-[#2b2c54]'>
              Dashboard
            </li>
          </Link>
          <Link className='inline-block' to='/account/profile'>
            <li className='my-3 text-xs text-[#111] font-medium hover:text-[#2b2c54]'>
              Account
            </li>
          </Link>
          <Link className='inline-block' to='/account/feedback'>
            <li className='my-3 text-xs text-[#111] font-medium hover:text-[#2b2c54]'>
              Feedback
            </li>
          </Link>
          <li
            className='my-3 text-xs font-medium text-[#ff0000] cursor-pointer'
            onClick={handleLogout}
          >
            Sign Out
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserAuthMenu;
